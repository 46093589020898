.ReactVirtualized__Table__headerRow {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  justify-content: center;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  display: flex;
  justify-content: center;
  text-overflow: ellipsis;

  .MuiTableCell-head {
    flex: 1 1;
  }
}

.ReactVirtualized-flexContainer {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.ReactVirtualized-tableRow {
  cursor: initial;
}

.ReactVirtualized-tableRowHover {
  &:hover {
    background-color: grey;
  }
}

.ReactVirtualized__Table__sortableHeaderIcon {
  vertical-align: text-top;
}
