/**
 * Editor styles, toolbar, wrapper and editor classes
 */
.wrapper-class {
  padding: 0.5rem;
  width: 100%;
}

.editor-link-popup {
  height: auto;
}

.editor-class {
  background-color: #e1e1e1;
  padding: 0.5rem;
  border: 1px solid #fefefe;
}

.toolbar-class {
  border: 1px solid #fefefe;
}
