@import 'src/scss/global/variables';

body {
  position: relative;
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

*,
*::after,
*::before {
  outline: none;
}

.disabled-link {
  pointer-events: none;
}

.main-content-wrapper {
  display: flex;
}

.auth-wrapper {
  width: 100vw;
  height: 100vh;
  @include center();
  background: $bg-light;
}

/* Fix margin on itens created by EditorState */
.MuiTypography-root.MuiTypography-body2.MuiListItemText-secondary {
  div {
    p:first-of-type {
      margin: 0 !important;
    }
  }
}

// * * * Utils * * *
.center {
  @include center();
}

.absolute-center {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
}

// * * * Mui Elements * * *
.MuiOutlinedInput-notchedOutline {
  border-width: 0px !important;
}

.MuiOutlinedInput-root {
  width: 100% !important;
  height: 40px !important;
}

.MuiButton-root {
  font-weight: 400 !important;
  text-transform: none !important;
}

.MuiAlert-standardError {
  margin-bottom: 1.6rem;
}

.MuiAvatarGroup-root {
  * {
    border: none !important;
  }

  .MuiAvatarGroup-avatar {
    box-shadow: $hover-shadow;
  }
}

.MuiDataGrid-columnHeader,
.MuiDataGrid-cell {
  outline: none !important;
}

.MuiTablePagination-selectLabel {
  position: relative;
  color: transparent;

  &::after {
    content: 'Linhas por página:';
    color: black;
  }
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  max-height: 230px;
}

@media screen and (max-width: 900px) {
  .css-1pans1z-MuiDataGrid-virtualScroller {
    overflow-x: scroll !important;
  }
}

.MuiDataGrid-row {
  &:nth-child(odd) {
    background: $odd-grey;
  }
}

.css-1yyjtgv-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  border-left: none;
}

.MuiIconButton-root {
  color: $darker-grey !important;
}

.data-grid-view-icon {
  transition: $transition;

  &:hover {
    transition: $transition;
    color: $icon-green;
  }
}

.data-grid-edit-icon {
  transition: $transition;

  &:hover {
    transition: $transition;
    color: $icon-blue;
  }
}

.data-grid-delete-icon {
  transition: $transition;

  &:hover {
    transition: $transition;
    color: $icon-red;
  }
}

// JOB DETAILS
.jobs-item-status {
  padding: 0.4rem;
  width: max-content;
  border-radius: 0.5rem;

  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0.4rem;
  }
}

// TEMPORARY STYLES TO FIX MODALS
.delete-modal-wrapper {
  display: grid;
  place-items: center;
}

.delete-modal-container {
  width: 80vw;
  height: auto;
  background: $lighter-grey;
  border-radius: $border-radius;
  box-shadow: $bottom-shadow;
  padding: 1.4rem;

  .delete-modal-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    color: $font;

    margin-bottom: 1.4rem;

    h1 {
      margin: 0;
    }

    .delete-modal-close-icon {
      transition: $transition;
      cursor: pointer;
      color: $font;

      svg {
        font-size: 1.8rem;
      }

      &:hover {
        transition: $transition;
        color: $icon-red;
      }
    }
  }

  .delete-modal-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 1rem;
    align-items: center;
    justify-content: left;
  }

  .delete-modal-form-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
  }

  .MuiButton-root {
    height: 40px;
    background-color: $jobber-primary;

    &:hover {
      background-color: $hover-primary;
    }
  }

  .MuiButton-outlined {
    color: $jobber-primary;
    border-color: $jobber-primary;
    background-color: transparent;

    &:hover {
      color: $lighter-grey;
      border-color: $hover-primary;
      background-color: $hover-primary;
    }
  }

  .MuiAlert-root {
    margin-top: -0.6rem;
    margin-bottom: 1.2rem;
  }
}

// TEMPORARY STYLES
.MuiDataGrid-main {
  .requests-priority-1 {
    background-color: #ffcccb !important;
  }
}

.color-cell-container {
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 1px 1px 4px #00000057;
}

// REPORTS
.reports-container {
  padding: 1.4rem;
}

.reports-form-inner-title {
  margin: 0;
  padding-bottom: 0.4rem;
  margin-bottom: 0.4rem;
  border-bottom: 1px solid $mid-grey;

  h2 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 400;
  }
}

.reports-form-content {
  display: flex;
  flex-direction: row;
  gap: 0 1rem;
  flex-wrap: wrap;

  .form-input-container input {
    border: none;
  }

  .form-input-wrapper {
    width: 190px;
    margin-bottom: 0;
  }

  .form-input-label {
    padding-bottom: 0;
  }

  .MuiFormControl-root {
    width: 100%;
    min-width: 160px;
  }
}

.reports-form-end {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1.4rem;

  .MuiLoadingButton-root {
    height: 40px;
  }
}

.briefing {
  background: #f9f9f9;
  padding: 0.2rem;
  border-radius: $border-radius;
}

// JOBS IMPORT
.css-1e4tb08 #file {
  height: auto !important;
}