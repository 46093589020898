/* --- New Color Schema --- */

/* Jobber */
$bg-dark: #17162e;
// $bg-dark: #000000;
$bg-light: #f9f9f9;
// $jobber-primary: #005cff;
// $hover-primary: #00244f;
$jobber-primary: #3f19ff;
$hover-primary: #2a10af;

/* Utils */
$darker-grey: #262d33;
$grey: #9a9e9e;
$mid-grey: #e1e1e1;
$lighter-grey: #fefefe;
$hover-grey: #e1e1e170;
$orange: #ef553a;

/* Data Grid */
$odd-grey: #e1e1e13f;

$accent-blue: #0044f5;
$accent-green: #66c9d1;

/* Actions */
$error-red: #f95959;
$error-bg-red: #f959592d;
$warn-yellow: #f9e159;

/* General */
$font: #17162e;
// $font: #000000;
$font-white: #ffffff;

/* Data action icons */
$icon-green: hsl(145, 50%, 60%);
$icon-blue: hsl(223, 60%, 60%);
$icon-red: hsl(0, 60%, 65%);

/*
 * Misc 
*/

/* Border */
$border-radius: 4px;

/* Shadow */
$box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
$cell-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
$bottom-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
$hover-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);

/* Transition */
$transition: 0.2s ease-in;

/* Breakpoints */
$layout-breakpoint-large: 1920px;
$layout-breakpoint-medium: 1440px;
$layout-breakpoint-small: 960px;
$layout-breakpoint-xsmall: 480px;

/* Schedules */
$schedule-user: #f9f9f9;
$schedule-weekend: #fcf8e3;
$schedule-holiday: #f9e3e4;

/* Mixins */
@mixin center() {
  display: flex;
  align-items: center;
  justify-content: center;
}
